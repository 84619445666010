import React from 'react';
import { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom'

const MonthSelectMenuOption = ({time, dateToStringConversionFunc}) => {
//        {time.getMonth()+1}/{time.getFullYear()}
  if (time == "") {
    return (
      <option value="" selected>
        select time
      </option>
    )
  } else {
    return (
      <option value={dateToStringConversionFunc(time)}>
        {time.getFullYear()}/{time.getMonth()+1}/{time.getDate()}
      </option>
    )
  }
}

const MonthSelectMenu = ({menuName, menuId, getStateVar, setStateVar, dateToStringConversionFunc}) => {
  var time = new Date();
//  time = new Date(time.getFullYear(), time.getMonth() + 1, 1);
  time = new Date(time.getFullYear(), time.getMonth(), time.getDate() + 1);
  const timestamps = [];
  for (let i = 1; i < 100; i++) {
    timestamps.push(time);
//    time = new Date(time.getFullYear(), time.getMonth() - 1, 1)
    time = new Date(time.getFullYear(), time.getMonth(), time.getDate() - 1)
  }
  return (
    <select name={menuName} id={menuId} value={getStateVar(menuName)} onChange={(e) => setStateVar(menuName, document.getElementById(menuName).value)}>
      <MonthSelectMenuOption time="" />
      {timestamps.map((timestamp) => <MonthSelectMenuOption time={timestamp} dateToStringConversionFunc={dateToStringConversionFunc} />)}
    </select>
  )
}

const ResourceItem = ({resource, loadDeviceVisitStatistics}) => {
  return (
    <tr>
      <td>{resource.id}</td>
      <td><Link to='/deviceVisitStatistics' onClick={() => loadDeviceVisitStatistics(resource.customerId)}>{resource.customerId}</Link></td>
      <td>{resource.pixelCode}</td>
      <td>{resource.hostName}</td>
    </tr>
  )
}

const ResourceList = ({resources, loadDeviceVisitStatistics}) => {

  return (
    <table>
      <thead>
        <tr>
          <th>RecID</th>
          <th>Customer ID</th>
          <th>Pixel code</th>
          <th>Host name</th>
        </tr>
      </thead>
      <tbody>
        {resources.map((resource) => <ResourceItem key={resource.id} resource={resource} loadDeviceVisitStatistics={loadDeviceVisitStatistics} />)}
      </tbody>
    </table>
  )
}

const ResourcePage = ({isAuthenticated, getResourcePage, setResourcePage, urlLogin, resources, loadDeviceVisitStatistics, getStateVar, setStateVar, dateToStringConversionFunc}) => {

  const navigate = useNavigate();
  useEffect(() => { if (!isAuthenticated()) navigate(urlLogin); }, []);

  return (
    <>
    <h2>Resources</h2>
    <button onClick={() => setResourcePage(0)}>First page</button>
    <button onClick={() => setResourcePage(-1)}>Previous page</button>
    Page: { getResourcePage() + 1 }
    <button onClick={() => setResourcePage(1)}>Next page</button>
      From:
      <MonthSelectMenu menuName="deviceVisitStatisticsFromDate" menuId="deviceVisitStatisticsFromDate" getStateVar={getStateVar} setStateVar={setStateVar} dateToStringConversionFunc={dateToStringConversionFunc} />
       To:
      <MonthSelectMenu menuName="deviceVisitStatisticsToDate" menuId="deviceVisitStatisticsToDate" getStateVar={getStateVar} setStateVar={setStateVar} dateToStringConversionFunc={dateToStringConversionFunc} />
    <ResourceList resources={resources} loadDeviceVisitStatistics={loadDeviceVisitStatistics} />
    </>
  )
}

export default ResourcePage
