import React from 'react';


class AdminLogsForm extends React.Component {

  constructor(props) {
    super(props)
//    this.state = {
//      loggingLevel: '<unknown>'
//    }
//    this.props.getLoggingLevel(function(response) {this.setState({ 'loggingLevel': response == undefined ? '<undefined>' : response })})
//    this.getLoggingLevel()
  }

  getLoggingLevel() {
    this.props.getLoggingLevel(function(response) {document.getElementById("loggingLevel").value = response})
  }

  setLoggingLevel(level) {
    this.props.setLoggingLevel(level, function(response) {document.getElementById("loggingLevel").value = response})
  }

  render() {
    return (
      <>
      <h2>Administrative Panel - Logs administration</h2>
      <table align="center"><tbody>
        <tr>
          <td align="right">Current effective logging level:</td>
          <td>
            <input id="loggingLevel" type="text" value="" size="15" />
            <button onClick={() => this.getLoggingLevel()}>Get logging level</button>
          </td>
        </tr>
        <tr>
          <td align="right">Set logging level:</td>
          <td>
            <select name="newLevel" id="selNewLevel" defaultValue={document.getElementById("loggingLevel")?.value ? document.getElementById("loggingLevel")?.value : ""}>
              <option value="">></option>
              <option value="TRACE">TRACE</option>)
              <option value="DEBUG">DEBUG</option>)
              <option value="INFO">INFO</option>)
              <option value="WARN">WARN</option>)
              <option value="ERROR">ERROR</option>)
            </select>
            <button onClick={() => this.setLoggingLevel(document.getElementById("selNewLevel").value)}>Set logging level</button>
          </td>
        </tr>
      </tbody></table>
      </>
    );
  }

//  componentDidMount() {
//    this.getLoggingLevel()
//  }
}

export default AdminLogsForm
