import React from 'react';
import {useParams} from 'react-router-dom';

const messages = {
  'statistics': 'Статистика посещений',
  'fetchMs': 'Время обработки запроса СУБД, мс',
  'fromTimestampUTC': 'Начала временного интервала, UTC',
  'toTimestampUTC': 'Конец временного интервала, UTC',
  'processMs': 'Время обработки данных, мс',
  'pixelCode': 'Код пикселя',
  'numDevices': 'Количество уникальных устройств',
  'numDevicesDifferentDates': 'Устройств, активных более 1 суток',
  'numDevicesBots': 'Предположительных ботов',
  'numDevicesByNumVisitsSlotsMap': 'Количество устройств, посетивших ресурс',
  'details': 'Детализация посещений'
}

const GetMessage = ({key, upperKey, previousKey}) => {
//    console.log(key, upperKey)
  if (key != undefined && messages[key] != undefined) {
//    console.log("returning ", key, messages[key])
    return messages[key];
  } else if (upperKey == "numDevicesByNumVisitsSlotsMap") {
    // calculated names for device visit slots
    if (key == 9223372036854775807 && previousKey != null) {
      let result = "более " + previousKey + " раз";
      if ([2, 3, 4].includes(key % 10)) {
        result = result + "а";
      }
      return result
    } else {
      let result = key + " раз"
      if ([2, 3, 4].includes(key % 10)) {
        result = result + "а";
      }
      if (previousKey != "" && !isNaN(previousKey) && parseInt(previousKey) + 1 < parseInt(key)) {
        result = parseInt(previousKey) + 1 + " - " + result;
      }
      return result
    }
//    return "от " + previousKey + " до " + key + " раз"
  } else {
//    console.log("returning ", key)
    return key;
  }
}

const StatisticsEntry = ({parameter, level, upperKey, previousKey}) => {
  const [key, value] = parameter;
//  console.log(key, value, level, upperKey, previousKey)
//  Create proper offset
  const rowsOffset = [];
  for (let i = 0; i < level; i++) {
    rowsOffset.push(<td></td>);
  }
// Create sequential key references for embedded object
  const rowsEmbedded = [];
  if (value instanceof Object) {
    let previousKey = "";
    for (var entry of Object.entries(value)) {
      const [embeddedKey, embeddedValue] = entry;
//      console.log(embeddedKey, embeddedValue);
      rowsEmbedded.push(<StatisticsEntry parameter={entry} level={level + 1} upperKey={key} previousKey={previousKey} />);
      previousKey = embeddedKey;
    }
  }
  return (
    <>
    <tr>
    {rowsOffset}
      <td align="left">{GetMessage({key, upperKey, previousKey})}</td>
      <td align="left">{value instanceof Object ? ':' : value == null ? '' : !value ? '' : ''+value}</td>
    </tr>
    {rowsEmbedded}
    </>
  );
//    {value instanceof Object ? Object.entries(value).map((embeddedParameter) => <StatisticsEntry parameter={embeddedParameter} level={level + 1} upperKey={key} />) : ""}
}

const DeviceVisitStatistics = ({statistics}) => {
  return (
    <table>
      <thead>
        <tr>
          <th>L1</th>
          <th>L2</th>
          <th>L3</th>
          <th>L4</th>
          <th>L5</th>
        </tr>
      </thead>
      <tbody>
        {Object.entries(statistics).map((parameter) => <StatisticsEntry parameter={parameter} level={0} />)}
      </tbody>
    </table>
  )
}

export default DeviceVisitStatistics
