import React from 'react';
import { Link } from 'react-router-dom'

class AdminForm extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      firstName: '', middleName: '', lastName: '', organizationNickname: '',
      customerNickname: '', customerOrganizationNickname: ''
    }
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value })
  }

  handlePermitRegistration(event) {
    event.preventDefault()
    if (this.state.firstName == '' || this.state.lastName == '' || this.state.organizationNickname == '') {
      alert('All the obligatory user registration information should be provided');
    } else {
      this.props.permitUserRegistration(this.state.firstName, this.state.middleName, this.state.lastName, this.state.organizationNickname, function(response) {document.getElementById("txtRegistrationToken").value = response?.data?.registrationToken});
    }
  }

  handleRegisterCustomer(event) {
    event.preventDefault()
    if (this.state.customerNickname == '' || this.state.customerOrganizationNickname == '' ) {
      alert('All the obligatory customer registration information should be provided');
    } else {
      this.props.registerCustomer(this.state.customerNickname, this.state.customerOrganizationNickname, function(response) {document.getElementById("txtCustomerApiKey").value = response?.data?.apiKey});
    }
  }

  render() {
    return (
      <>
      <h2>Administrative Panel</h2>
      <list>
        <li><Link to='/admin/logs'>Logs settings</Link></li>
      </list>
      <form onSubmit={(event) => this.handlePermitRegistration(event)} id="formPermitRegistration">
        <table align="center"><tbody>
          <tr><td colSpan="2">PERMIT USER REGISTRATION</td></tr>
          <tr>
            <td align="right">* First name:</td>
            <td><input type="text" name="firstName" placeholder="first name" value={this.state.firstName} onChange={(event) => this.handleChange(event)} /></td>
          </tr>
          <tr>
            <td align="right">Middle name:</td>
            <td><input type="text" name="middleName" placeholder="middle name" value={this.state.middleName} onChange={(event) => this.handleChange(event)} /></td>
          </tr>
          <tr>
            <td align="right">* Last name:</td>
            <td><input type="text" name="lastName" placeholder="last name" value={this.state.lastName} onChange={(event) => this.handleChange(event)} /></td>
          </tr>
          <tr>
            <td align="right">* Organization nickname:</td>
            <td><input type="text" name="organizationNickname" placeholder="organization nickname" value={this.state.organizationNickname} onChange={(event) => this.handleChange(event)} /></td>
          </tr>
          <tr>
            <td colSpan="2"><input type="submit" value="Permit registration" /></td>
          </tr>
        </tbody></table>
      </form>
      <table align="center"><tbody>
        <tr><td align="center" colSpan="2"><b><u>Results</u></b></td></tr>
        <tr>
            <td align="right">Registration token:</td>
            <td><input id="txtRegistrationToken" type="text" value="" size="155" /></td>
        </tr>
      </tbody></table>
      <br></br>
      <form onSubmit={(event) => this.handleRegisterCustomer(event)} id="formRegisterCustomer">
        <table align="center"><tbody>
          <tr><td colSpan="2">REGISTER CUSTOMER</td></tr>
          <tr>
            <td align="right">* Nickname:</td>
            <td><input type="text" name="customerNickname" placeholder="nickname" value={this.state.customerNickname} onChange={(event) => this.handleChange(event)} /></td>
          </tr>
          <tr>
            <td align="right">* Organization nickname:</td>
            <td><input type="text" name="customerOrganizationNickname" placeholder="organization nickname" value={this.state.customerOrganizationNickname} onChange={(event) => this.handleChange(event)} /></td>
          </tr>
          <tr>
            <td colSpan="2"><input type="submit" value="Register customer" /></td>
          </tr>
        </tbody></table>
      </form>
      <table align="center"><tbody>
        <tr><td align="center" colSpan="2"><b><u>Results</u></b></td></tr>
        <tr>
            <td align="right">Customer's personal API key:</td>
            <td><input id="txtCustomerApiKey" type="text" value="" size="155" /></td>
        </tr>
      </tbody></table>
      </>
    );
  }

}

export default AdminForm
